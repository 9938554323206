const DiscordIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_844_4184)">
        <path
          d="M16.5948 1.751C15.3649 1.16658 14.0498 0.741847 12.675 0.5C12.5061 0.810039 12.3088 1.22705 12.1728 1.55878C10.7113 1.33555 9.26323 1.33555 7.82858 1.55878C7.6926 1.22705 7.49086 0.810039 7.32049 0.5C5.94414 0.741847 4.62756 1.16814 3.39765 1.7541C0.916926 5.5613 0.244441 9.27393 0.580684 12.9338C2.22603 14.1817 3.82057 14.9398 5.38821 15.4358C5.77526 14.8948 6.12046 14.3197 6.41785 13.7136C5.85147 13.495 5.309 13.2253 4.79643 12.9122C4.93242 12.8098 5.06543 12.7029 5.19393 12.5928C8.32024 14.0779 11.717 14.0779 14.806 12.5928C14.936 12.7029 15.069 12.8098 15.2035 12.9122C14.6894 13.2268 14.1455 13.4966 13.5791 13.7151C13.8765 14.3197 14.2202 14.8964 14.6087 15.4374C16.1779 14.9413 17.7739 14.1833 19.4192 12.9338C19.8138 8.69107 18.7453 5.01253 16.5948 1.751ZM6.84376 10.683C5.90528 10.683 5.13565 9.79323 5.13565 8.70967C5.13565 7.6261 5.88884 6.73477 6.84376 6.73477C7.79871 6.73477 8.56832 7.62454 8.55188 8.70967C8.55337 9.79323 7.79871 10.683 6.84376 10.683ZM13.1562 10.683C12.2177 10.683 11.4481 9.79323 11.4481 8.70967C11.4481 7.6261 12.2012 6.73477 13.1562 6.73477C14.1111 6.73477 14.8807 7.62454 14.8643 8.70967C14.8643 9.79323 14.1111 10.683 13.1562 10.683Z"
          fill="#5865F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_844_4184">
          <rect
            width="19"
            height="15"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DiscordIcon;
