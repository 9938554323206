const en = {
  title: 'Decision Table',
  learnHowToUse: 'Learn how to use this tool',
  tableExampleText: 'Decision Table example',
  article: 'Article',
  image: 'Image',
  tableTitles: [
    {
      toolTipContent: 'Suggested solution',
      title: 'What do we do?',
    },
    {
      toolTipContent: 'Justification of our solution',
      title: 'Why (bias)?',
    },
    {
      toolTipContent: 'Justification of our solution',
      title: 'Why?',
    },
    {
      toolTipContent: 'Methods used to identify biases',
      title: 'Validation method',
    },
  ],
  exportBtnText: 'Export',
  fullScreenBtn: 'Fullscreen view',
  saveBtn: 'Save to your profile',
  saveTxt: 'Save',
  savedState: 'Saved',
  loginToEdit: 'Login to edit',
  login: 'Log In',
  logoutText: 'Log Out',
  closeBtn: 'Close',
  savedPersonasTitles: [
    'Persona name',
    'Creation date',
    'Last modified',
    'Actions',
  ],
  open: 'Open',
  deleteBtn: 'Delete',
  loginText: 'To use this feature you have to log in.',
  singInWithGoogle: 'Sign in with Google',
  signInWithLinkedIn: 'Sign in with LinkedIn',
  signInWithDiscord: 'Sign in with Discord',
  copyLink: 'Copy URL',
  cancelBtn: 'Cancel',
  availablePersonasTxt: 'Number of available Personas:',
  personasIsFull:
    'Your persona list is full.\n You can free up some space by deleting one of your  Saved Personas.',
  overWriteText: 'Overwrite',
  savedPersonasText: 'Saved Personas',
};
export default en;
