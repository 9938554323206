import { FC, useContext } from 'react';
import { useRouter } from 'next/router';

import decisionTable from '@data/decisionTable';

import { TRouter } from '@local-types/global';

import Modal from '@components/Modal';
import Button from '@components/Button';

import GoogleIcon from '@icons/GoogleIcon';
import LinkedInIcon from '@icons/LinkedInIcon';
import DiscordIcon from '@icons/DiscordIcon';

import styles from './LogInModal.module.scss';
import { signOut, useSession } from 'next-auth/react';
import { GlobalContext } from '@components/Context/GlobalContext';

type LoginModalProps = {
  setShowModal: (showModal: boolean) => void;
};
const LogInModal: FC<LoginModalProps> = ({ setShowModal }) => {
  const { locale } = useRouter() as TRouter;
  const { accountData } = useContext(GlobalContext);
  const router = useRouter();
  const isProduction = process.env.NEXT_PUBLIC_ENV === 'prod';
  const { data: session } = useSession();
  const {
    singInWithGoogle,
    signInWithLinkedIn,
    signInWithDiscord,
    loginText,
    cancelBtn,
    login,
  } = decisionTable[locale];

  const handleClose = () => {
    setShowModal(false);
  };

  const handleProviderSignIn = async (provider: string) => {
    if (session && accountData === null) {
      await signOut({ redirect: false });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('provider');
      sessionStorage.clear();
      document.cookie =
        'next-auth.session-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      setTimeout(() => {
        router.replace(`/auth?provider=${provider}`);
      }, 100);
    } else {
      router.push(`/auth?provider=${provider}`);
    }
  };

  return (
    <Modal
      onClick={handleClose}
      wrapperClassName={styles.loginWrapper}
      bodyClassName={styles.loginBody}
    >
      <div className={styles.contentWrapper}>
        <h1 className={styles.title}>{login}</h1>
        <span className={styles.description}>{loginText}</span>
        <div className={styles.buttonWrapper}>
          <a
            onClick={() => handleProviderSignIn('google')}
            className={styles.link}
          >
            <GoogleIcon /> <span>{singInWithGoogle}</span>
          </a>
          {!isProduction && (
            <a
              onClick={() => handleProviderSignIn('linkedin')}
              className={styles.link}
            >
              <LinkedInIcon /> <span>{signInWithLinkedIn}</span>
            </a>
          )}
          <a
            onClick={() => handleProviderSignIn('discord')}
            className={styles.link}
          >
            <DiscordIcon /> <span>{signInWithDiscord}</span>
          </a>
          <Button
            label={cancelBtn}
            onClick={handleClose}
            className={styles.cancelBtn}
          />
        </div>
      </div>
    </Modal>
  );
};
export default LogInModal;
